import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

//firebase
import firebase from "@/utils/firebaseInit"
import {dbCollections} from "@/utils/firebaseCollection"
const db = firebase.firestore()
const months=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'no', sortable: false },
    { key: 'course_name',sortable: true },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(null)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const isSpinner = ref(null)

  const docId = ref(null)
  const fieldName = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  
  watch([currentPage, perPage, searchQuery, roleFilter, planFilter], () => {
    refetchData()
  })
  
  var usersList = []
  var docFound = false
  var users = []
  const fetchUsers = (ctx, callback) => {
    if(!docFound)
    {
      isSpinner.value = true
      users = []
    }
    console.log("get List")
    if(usersList.length==0 && !docFound)
    {
      db
        .collection(dbCollections.FIELDANDCOURSE)
        .doc(docId.value)
        .get()
        .then(doc=>{
          if(doc.exists && !doc.data().isDeleted)
          {
            docFound=true
            fieldName.value = doc.data().fieldName
            var startIndex = (currentPage.value-1)*perPage.value+1
            var endIndex = startIndex+perPage.value-1
            var index = 1
            var dataIndex = 1
            console.log(startIndex,endIndex,doc.data())
            if(doc.data().courses.length==0)
            {
              totalUsers.value = usersList.length
              isSpinner.value = false
              callback(users)
            }
            else
            {
              doc.data().courses.forEach(data=>{
  
                //Turn isDeleted Flag = false
                // db
                //   .collection(dbCollections.FIELDANDCOURSE)
                //   .doc(doc.id)
                //   .update({
                //     isDeleted:false
                //   })
                //   .catch(error => console.log(error))
  
                usersList.push(data)

                usersList[usersList.length-1].course_name=data.name
  
                if(dataIndex>=startIndex && dataIndex<=endIndex)
                {
                  users.push({
                    'course_name':data
                  })
                  dataIndex++;
                }
                if(index==doc.data().courses.length)
                {
                  totalUsers.value = usersList.length
                  isSpinner.value = false
                  console.log("Output")
                  callback(users)
                }
                index++;
              })
            }
          }
          else
          {
            totalUsers.value = doc.data().courses.length
            console.log("Output")
            isSpinner.value = false
            callback(users)
          }
        })
        .catch(error => {
          isSpinner.value = false
          console.log("Output")
          console.log(error)
        })
    }
    else
    {
      console.log("Already has list data")
      var startIndex = (currentPage.value-1)*perPage.value+1
      var endIndex = startIndex+perPage.value-1
      var index = 1
      var totalRecords = 0
      // var dataIndex = 1
      console.log(startIndex,endIndex)
      users = []
      if(usersList.length==0)
      {
        totalUsers.value = usersList.length
        isSpinner.value=false
        callback(users)
        return;
      }

      if(isSortDirDesc.value==false)
      {
        console.log("Asc")
        usersList.sort((a, b) => (a[sortBy.value] > b[sortBy.value] ? 1 : -1))//Asc
      }
      else if(isSortDirDesc.value==true)
      {
        console.log("Desc")
        usersList.sort((a, b) => (a[sortBy.value] > b[sortBy.value] ? -1 : 1))//Desc
      }


      usersList.forEach(data=>{
        if(searchQuery.value=='')
        {
          if(index>=startIndex && index<=endIndex)
          {
            users.push({
              'course_name':data
            })
            // dataIndex++;
          }
          if(index==usersList.length)
          {
            // if(currentPage.value > 1 && users.length==0)
            // {
            //   currentPage.value -= currentPage.value
            // }
            totalUsers.value = usersList.length
            isSpinner.value = false
            callback(users)
          }
          index++;
        }
        else if(searchQuery.value!='')
        {
          if(
            data.name.toLowerCase().includes(searchQuery.value.toLowerCase())
          )
          {
            users.push({
              'course_name':data
            })
            totalRecords++
          }
          if(index==usersList.length)
          {
            // if(currentPage.value > 1 && users.length==0)
            // {
            //   currentPage.value -= currentPage.value
            // }
            var tmp = []
            var dataIndex = 1

            users.forEach(FD=>{
              if(dataIndex>=startIndex && dataIndex<=endIndex)
              {
                tmp.push(FD)
              }
              dataIndex++
            })
            totalUsers.value = totalRecords
            isSpinner.value = false
            callback(tmp)
          }
          index++;
        }
        else
        {
          isSpinner.value = false
        }
      })
      isSpinner.value=false
    }

    // for (let index = 0; index < 10; index++) {
    //   users.push({
    //     'No':index,
    //     'Date of Inquiry':`Date ${index}`,
    //     'Student Name':`Name ${index}`,
    //     'Phone no.':9462489464654,
    //     'College Name.':`Test`,
    //     'Branch.':`abcd`,
    //     'CGPA(%).':(index+1*(Math.random()*100)%100).toFixed(2),
    //     'actions':`<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" > Create Account </b-button> <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" > Create New Inquiry </b-button>`
    //   })
    // }
    // callback(users)
  }

  const deleteField = (ctx, callback)=>{
    var index = (currentPage.value-1) * perPage.value + ctx.index + 1
      db
        .collection(dbCollections.FIELDANDCOURSE)
        .doc(ctx.item.id)
        .update({
          isDeleted:true
        })
        .then(()=>{
          usersList.splice(index-1,1)
          refetchData()
        })
        .catch(error=>{
          console.log(error)
        })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    deleteField,

    isSpinner,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,

    docId,
    fieldName
  }
}
