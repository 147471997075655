<template>
  <div>
    <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
    <div v-show="!isSpinner">

      <!-- <user-list-add-new
        :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
        :role-options="roleOptions"
        :plan-options="planOptions"
        @refetch-data="refetchData"
      /> -->

      <!-- Filters -->
      <!-- <users-list-filters
        :role-filter.sync="roleFilter"
        :plan-filter.sync="planFilter"
        :status-filter.sync="statusFilter"
        :role-options="roleOptions"
        :plan-options="planOptions"
        :status-options="statusOptions"
      /> -->

      <b-card class="output_field">
          <b-row>
              <b-col md="3">
                  <b-form-group label="Field Name">
                      <b>{{fieldName}}</b>
                  </b-form-group>
              </b-col>
              <b-col md="3">
                  <b-form-group label="No. of Courses">
                      <b>{{totalUsers}}</b>
                  </b-form-group>
              </b-col>
          </b-row>
      </b-card>

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"            
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <h4 class="mb-0 table_title">Courses</h4>
            </b-col>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
            >
              <div style="margin-right:1.5rem;">
                  <b-form-input
                      v-model="searchQuery"
                      class="d-inline-block mr-1"
                      placeholder="Search..."
                  />
                  <!-- <b-button
                      variant="primary"
                      @click="isAddNewUserSidebarActive = true"
                  >
                      <span class="text-nowrap">Add User</span>
                  </b-button> -->
              </div>
              
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <!-- <label>entries</label> -->
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refUserListTable"
          class="position-relative fieldcourse_wrapper"
          :items="fetchUsers"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :show-empty="!isSpinner"
          empty-text="No matching records found"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: No. -->
          <template #cell(no)="data">
            <div>
                <span>{{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}</span>
            </div>
          </template>

          <template #cell(course_name)="data">
            <div>
                <span>{{data.item.course_name.name||data.item.course_name}}</span>
            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BModal,BFormGroup,BFormRadio,BFormDatepicker,
  BForm,BFormTextarea,BFormSelect,BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useUsersList from './useCourseList.js'
// import userStoreModule from '../userStoreModule'
// import UsersListFilters from './UsersListFilters.vue'
// import UserListAddNew from './UserListAddNew.vue'

//vee-validator
import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'
import { required, email,max,max_value,integer,numeric,min,min_value,alpha_spaces,double } from 'vee-validate/dist/rules';
extend('required',required)
extend('max',max)
extend('min',min)
extend('min_value',min_value)
extend('email',email)
extend('alpha_spaces',alpha_spaces)
extend('max_value',max_value)
extend('integer',integer)
extend('numeric',numeric)
extend('double',double)

import firebase from '@/utils/firebaseInit'
import firebaseTimeStamp from 'firebase'
import { dbCollections } from '@/utils/firebaseCollection'
import { ValidationFunction } from '@/utils/globalValidations.js';

const db = firebase.firestore()

import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    // UsersListFilters,
    // UserListAddNew,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormGroup,
    BFormRadio,
    BFormDatepicker,
    BFormTextarea,
    BFormSelect,
    vSelect,
    BSpinner,
  },
  data(){
    return{

    }
  },
  created() {
    var self = this

    self.docId = self.$route.params.id

    if(!self.docId)
    {
      self.$router.push({name:'field-course-mgmt'})
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      closeInquiry,

      isSpinner,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      docId,
      fieldName
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      closeInquiry,

      isSpinner,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      docId,
      fieldName
    }
  },
  methods:{
    isOnlyDecimal(event) {
      ValidationFunction.OnlyFloatWithNumber(event);
    },
    isOnlyInteger(event) {
      ValidationFunction.OnlyIntegerNumber(event);
    },
    getIntakeNo(cb)
    {
        var self = this
        db
          .collection(dbCollections.USERS)
          .orderBy('createdAt')
          .get()
          .then((querySnapshot)=>{
              var newIntakeNo=0
              if(!querySnapshot.empty)
              {
                  querySnapshot.forEach(doc=>{
                      if(!doc.data().isDeleted && doc.data().role=="student")
                      {
                        var uniqueId = doc.data().uniqueId.split('/')
                        if(uniqueId[0]==self.selectedUser.item.docData.intake && uniqueId[1]==new Date().getFullYear() && uniqueId[2]>=newIntakeNo)
                        {
                            newIntakeNo = parseInt(uniqueId[2])+1
                        }
                        var zeroes = 3-newIntakeNo.toString().length

                        for (let i = 0; i < zeroes; i++) {
                          newIntakeNo='0'+newIntakeNo
                        }
                        console.log("last No",newIntakeNo,uniqueId,doc.data().uniqueId)
                      }
                  })
                  if(newIntakeNo==0)
                  {
                    newIntakeNo='001'
                  }
              }
              else
              {
                newIntakeNo='001'
              }
              cb(newIntakeNo)
          })
    },
  }
}
</script>
<style>
.position-relative.table-responsive thead tr th{
  white-space: nowrap !important;
}
/* .position-relative.table-responsive tbody tr td{
  white-space: nowrap !important;
} */

.s_modal_title{
    width: 100%;
    
    font-weight: 600;
    font-size: 20px;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
